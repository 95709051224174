@tailwind base;
@tailwind components;
@tailwind utilities;

html[data-theme='dark'] {
    --background-primary: #232529;
    /*--background-secondary: #161616;*/
    --background-secondary: rgba(29, 31, 35, 1);
    --text-accent: #7f6df2;
    --background-primary-alt: #1a1a1a;
    --background-secondary-alt: #282a2f;
    --background-modifier-border: #333;
    --background-modifier-form-field: rgba(0, 0, 0, 0.3);
    --background-modifier-form-field-highlighted: rgba(0, 0, 0, 0.22);
    --background-modifier-box-shadow: rgba(0, 0, 0, 0.3);
    --background-modifier-success: #197300;
    --background-modifier-error: #3d0000;
    --background-modifier-error-rgb: 61, 0, 0;
    --background-modifier-error-hover: #470000;
    --background-modifier-cover: rgba(0, 0, 0, 0.8);
    --text-accent-hover: #8875ff;
    --text-normal: #dcddde;
    --text-muted: #999;
    --text-muted-rgb: 153, 153, 153;
    --text-faint: #666;
    --text-error: #ff3333;
    --text-error-hover: #990000;
    --text-highlight-bg: rgba(255, 255, 0, 0.4);
    --text-highlight-bg-active: rgba(255, 128, 0, 0.4);
    --text-selection: rgba(23, 48, 77, 0.99);
    --text-on-accent: #dcddde;
    --interactive-normal: #2a2a2a;
    --interactive-hover: #303030;
    --interactive-accent: #483699;
    --interactive-accent-rgb: 72, 54, 153;
    --interactive-accent-hover: #4d3ca6;
    --interactive-success: #197300;
    --scrollbar-active-thumb-bg: rgba(255, 255, 255, 0.2);
    --scrollbar-bg: rgba(255, 255, 255, 0.05);
    --scrollbar-thumb-bg: rgba(255, 255, 255, 0.1);
    --highlight-mix-blend-mode: lighten;
    --icon-accent: #f0c000;

    --shade-100: #232529;
    --shade-60: #282a2f;
    --shade-50: #2d2f34;
    --shade-45: #2f3237;
    --shade-40: #5e646e;
    --shade-30: #6a707c;
    --shade-20: #acb1b9;
    --shade-10: #d8dadf;
    --shade-05: #ecedef;
    --shade-00: #f1f2f4;
    --scrollbar-color: var(--shade-05);
    --code-background: var(--shade-45);
    --code-border-color: var(--shade-40);
    --editor-url-color: #92b5f3;
}

:root,
html[data-theme='light'] {
    --background-primary: #ffffff;
    --background-primary-alt: #f5f6f8;
    --background-secondary: #f2f3f5;
    --background-secondary-alt: #e3e5e8;
    --background-modifier-border: #ddd;
    --background-modifier-form-field: #fff;
    --background-modifier-form-field-highlighted: #fff;
    --background-modifier-box-shadow: rgba(0, 0, 0, 0.1);
    --background-modifier-success: #A4E7C3;
    --background-modifier-error: #990000;
    --background-modifier-error-rgb: 230, 135, 135;
    --background-modifier-error-hover: #bb0000;
    --background-modifier-cover: rgba(0, 0, 0, 0.8);
    --text-accent: #705dcf;
    --text-accent-hover: #7a6ae6;
    --text-normal: #2e3338;
    --text-muted: #888888;
    --text-muted-rgb: 136, 136, 136;
    --text-faint: #999999;
    --text-error: #800000;
    --text-error-hover: #990000;
    --text-highlight-bg: rgba(255, 255, 0, 0.4);
    --text-highlight-bg-active: rgba(255, 128, 0, 0.4);
    --text-selection: rgba(204, 230, 255, 0.99);
    --text-on-accent: #f2f2f2;
    --interactive-normal: #f2f3f5;
    --interactive-hover: #e9e9e9;
    --interactive-accent: #7b6cd9;
    --interactive-accent-rgb: 123, 108, 217;
    --interactive-accent-hover: #8273e6;
    --interactive-success: #197300;
    --scrollbar-active-thumb-bg: rgba(0, 0, 0, 0.2);
    --scrollbar-bg: rgba(0, 0, 0, 0.05);
    --scrollbar-thumb-bg: rgba(0, 0, 0, 0.1);
    --highlight-mix-blend-mode: darken;
    /*--text-accent: #f0c000;*/
    --icon-accent: #f0c000;

    --shade-00: #2d2f34;
    --shade-05: #3f434a;
    --shade-10: #5e646e;
    --shade-20: #6a707c;
    --shade-30: #acb1b9;
    --shade-40: #d8dadf;
    --shade-45: #ecedef;
    --shade-50: #f1f2f4;
    --shade-60: #f6f7f9;
    --shade-100: #fcfcfd;
    --scrollbar-color: var(--shade-40);
    --code-background: var(--shade-45);
    --code-border-color: var(--shade-40);
    --editor-url-color: #3970cd;
}

:root {
    background-color: var(--background-primary);
    --note-editor-font-size: 16px;
    --note-editor-line-height: 1.6em;
    --bg-accent: rgba(22, 104, 227, 1);
    --bg-accent-hover: rgba(19, 83, 202, 1);
    --font-family-system: "UniversalSans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --scrollbar-color-hover: var(--shade-20);
    --editor-line-height: calc(var(--note-editor-font-size) * var(--note-editor-line-height));
}

/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/
.bg-primary {
    background: var(--background-primary);
}
.shade-50{
    background-color: var(--shade-50);
}
.bg-secondary {
    background: var(--background-secondary);
}

.bg-primary-alt {
    background: var(--background-primary-alt)
}

.bg-secondary-alt {
    background: var(--background-secondary-alt)
}


body {
    font-family: var(--font-family-system) !important;
    color: var(--text-normal);
    background: var(--background-primary);
    /*font-family: 'Poppins', sans-serif;*/
    line-height: 1.6em;
}

* {
    font-family: var(--font-family-system) !important;
}

.-ml-full {
    margin-left: -50em;
}

.text-muted {
    color: var(--text-muted);
}

.hover\:text-hover:hover {
    color: var(--text-accent-hover);

}

.hover\:bg-hover:hover {
    background: var(--text-accent-hover);
}

.hover\:blue-hover:hover {
    color: var(--bg-accent);
    background-color: white;
    border-radius: 4px;
}

html[data-theme='light'] .d-block-light,
html[data-theme='dark'] .d-block-dark {
    display: block !important;
}

.d-none {
    display: none !important;
}


.content {
    background: var(--background-primary);
    /*height: calc(100vh - 2.5em);*/
    color: var(--text-normal);
    /*color: var(--text-muted);*/
    /*transition: all 700ms ease-in-out;*/
}

.icon {
    /*color: var(--text-muted);*/
}

.icon-accent {
    color: var(--icon-accent);
}

.bg-accent {
    background: var(--bg-accent);
}

.text-accent-blue {
    color: var(--bg-accent);
}

.bg-accent-blue {
    background-color: var(--bg-accent);
    color: white;
}

.bg-accent-blue:hover {
    background-color: var(--bg-accent-hover);
}

.text-accent {
    color: var(--text-accent);
}

.hover\:text-hover-accent:hover {
    color: var(--text-accent);
}

.hover\:search-list-hover:hover {
    background-color: var(--background-secondary);
}

/*.icon:hover {*/
/*	color: var(--text-accent-hover);*/
/*}*/

.menu-ti_tle {
    background: var(--background-primary);
}

.css-cveggr-MuiListItemIcon-root {
    min-width: 25px !important;
}

button:hover {
    cursor: pointer !important;
}

.MuiListItem-button:not(.Mui-selected):hover {
    background: var(--background-secondary-alt) !important;
    @apply rounded  !important
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
    font-weight: 800;
}

.css-tlelie-MuiListItemText-root {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

/* Toggle B */
input:checked ~ .dot {
    transform: translateX(2rem);
}

.Mui-selected {
    /*opacity: 0.5 !important;*/
    background-color: var(--background-secondary-alt) !important;
    color: var(--text-normal) !important;
    @apply rounded  !important
}

.sidebar-avatar {
    border-top: 1px solid var(--background-modifier-border);
}

input {
    background: var(--background-primary);
    outline: none;
    border: none;
}

#search-input, #new-folder {
    background: var(--background-secondary);
}

#search-input:focus, #new-folder:focus {
    border: 1px solid var(--interactive-accent);
}

.text-s {
    font-size: 0.90rem;
    line-height: 1.5rem;
}

.text-normal {
    color: var(--text-normal);
}

/*.modal {*/
/*	position: absolute;*/
/*	top: 20%;*/
/*	left: 50%;*/
/*	transform: translate(-50%, -50%);*/
/*	width: 50vw;*/
/*	background-color: var(--background-primary-alt);*/
/*	border: 1px solid var(--background-modifier-border);*/
/*	box-shadow: var(--background-modifier-box-shadow);*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*	.modal {*/
/*		width: 100vw;*/
/*		!*left: 0;*!*/
/*	}*/
/*}*/

.sidebar-active {
    background-color: var(--text-accent-hover) !important;
    @apply rounded
}

.sidebar {
    background-color: var(--background-secondary);
}

.text-more-muted {
    opacity: 0.5;
    color: var(--text-muted)
}


.MuiTooltip-tooltip {
    font-size: 0.75rem !important;
    line-height: 1.25rem !important;
    background-color: black !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
    /*opacity: 1 !important;*/
    /*transition: none!important;*/
}

input::placeholder {
    opacity: 0.5;
    color: var(--shade-20);
}


/* Placeholder */
.quill > .ql-container > .ql-editor.ql-blank::before {
    opacity: 0.5;
    color: var(--shade-20);
    @apply text-base
}

.text-shade-20 {
    color: var(--shade-20);
}

.tool {
    background-color: var(--background-secondary-alt);
}

.editor {
    height: calc(100vh - 4rem);
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    @apply mb-2 font-semibold;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 4px;
}

.ql-bubble .ql-editor h1 {
    font-size: 2em;
}

.ql-bubble .ql-editor h2 {
    font-size: 1.7em;
}

.ql-bubble .ql-editor h3 {
    font-size: 1.5em;
}

.ql-bubble .ql-editor code, .ql-bubble .ql-editor pre {
    background-color: var(--code-background) !important;
    border: 1px solid var(--code-border-color) !important;
    border-radius: 3px!important;
    color: var(--text-normal) !important;
    @apply text-base;
}

.ql-bubble .ql-editor p,
.ql-bubble .ql-editor ul,
.ql-bubble .ql-editor ol {
    line-height: 1.6em;
    font-size: 1rem;
}
.ql-editor a{
    color: var(--editor-url-color) !important;
}
.ql-editor ul, .ql-editor ol {
    @apply my-4
}

.content {
    width: 680px;
}

.sidebar-open {
    height: calc(100vh - 4rem);
}

.modal-box {
    color: var(--text-normal) !important;
    width: 720px !important;
    background-color: var(--background-primary) !important;
    box-shadow: var(--background-modifier-box-shadow) !important;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    padding: 0!important;
}

.modal-box-folder {
    color: var(--text-normal) !important;
    width: 500px !important;
    background-color: var(--background-primary) !important;
    box-shadow: var(--background-modifier-box-shadow) !important;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
}

.quill {
    width: 680px;
    color: var(--shade-05);
    line-height: var(--editor-line-height);
}

.title {
    color: var(--shade-05);
}

@media only screen and (max-width: 1023.94px) {
    .quill {
        width: 100%;
    }
}

@media only screen and (max-width: 767.94px) {
    .modal-box, .modal-box-folder {
        width: 90vw !important;
    }
}

@media only screen and (max-width: 767.94px) {
    .quill {
        /*width: 100%;*/
        /*padding-left: 1em;*/
        /*padding-right: 1em;*/
    }
}

._flex, ._quill, ._truncate {
    /*outline: #2e3338;*/
    outline: 1px dashed red;
}

/*.search:focus {*/
/*	background-color: var(--interactive-hover);*/
/*}*/

.bread-crumb {
    color: #acb1b9;
}

.toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-4 w-4 transition shadow-sm;
}

input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
}

input:checked + .toggle-bg {
    @apply bg-blue-600 border-blue-600;
}

.icon-caret {
    color: var(--shade-30);
}

.icon-folder {
    color: var(--shade-30)
}

.dropdown-content {
    background-color: var(--background-secondary);
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--background-secondary);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: var(--background-secondary);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--shade-40);
    border-radius: 6px;
    border: 2px solid transparent
}

.error {
    color: var(--text-error);
}
